const colors = {
  // project pallete
  orangeRed: '#ff6b5d',
  white: '#ffffff',
  black: '#000000',
  charcoal: '#4b4b4b',
  grey: '#727272',
  greyMedium: '#8a8a8a',
  greyLight: '#d8d8d8',
  greyLightest: '#f7f7f7',
  green: '#1fab7c',
  purpleDark: '#6858ff',
  purple: '#7B6DFF',
  orange: '#FF8648',
  pumpkin: '#F5A800',
  red: '#ff7063',
  darkRed: '#fd6355',
  yellow: '#ffe469',
  // btn colors
  btnBlueDefault: '#6858ff',
  btnBlueHover: '#3420ff',
  btnBlueActive: '#7b6dff',
  btnBlackDefault: '#000',
  btnBlackHover: '#4b4b4b',
  btnBlackActive: '#727272',
}

export default { ...colors }
