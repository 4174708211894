import colors from './colors'

const misc = {
  viewport: {
    'a-vp': '320px',
    'b-vp': '480px',
    'c-vp': '576px',
    'd-vp': '768px',
    'e-vp': '992px',
    'f-vp': '1200px',
  },
  transitions: {
    duration: '0.1s',
    properties: 'all',
    easing: 'linear',
    delay: '0s',
  },
  dropdownZindex: 10,
  navZindex: 100,
  modalZindex: 1050,
  hamburgerMenuZIndex: 300,
  layoutChangeBreakpoint() {
    return this.viewport['e-vp']
  },
  containerPaddingBreakpoint() {
    return this.viewport['d-vp']
  },
  smallBorderRadius: '4px',
  mediumBorderRadius: '6px',
  largeBorderRadius: '20px',
  defaultTextMargin: '5px 0',
  borderRadius: {
    sm: `2px`,
    md: `4px`,
    lg: `6px`,
  },
  boxShadow: {
    inputField: `0 2px 4px 0 rgba(0, 0, 0, 0.18)`,
    inputFieldWithBorder: `0 2px 4px 0 rgba(0, 0, 0, 0.18), 0px 0px 0px 1px ${colors.greyLight}`,
    default: `0 4px 6px 0 rgba(0, 0, 0, 0.16)`,
  },
  input: {
    paddingVertical: '12px',
    paddingHorizontal: '24px',
  },

  // Spacing vars
  gutterSpacings: {
    default: '15px',
    'max-c-vp': '25px',
  },
  rowColumnSpacing: '5px',
}

export default { ...misc }
