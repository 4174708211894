import { css } from '@emotion/core'
import facepaint from 'facepaint'
import misc from '../tokens/misc'
import spacings from '../tokens/spacings'
import * as mixins from './mixins'

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['d-vp']),
  mixins.respondTo(misc.viewport['e-vp']),
  mixins.respondTo(misc.viewport['f-vp']),
])

const spacerBreakpointSizes = spacer => {
  return [
    `${spacer['max-c-vp']}px`,
    `${spacer['c-vp']}px`,
    `${spacer['d-vp']}px`,
    `${spacer['e-vp']}px`,
    `${spacer['f-vp']}px`,
  ]
}

const responsiveSpacingsGenerator = (spacingClass, spacingSize) => {
  switch (spacingClass) {
    case `padB${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          paddingBottom: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `padT${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          paddingTop: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `padL${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          paddingLeft: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `padR${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          paddingRight: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `padLR${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          paddingLeft: spacerBreakpointSizes(spacingSize),
          paddingRight: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `padBT${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          paddingBottom: spacerBreakpointSizes(spacingSize),
          paddingTop: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `mrB${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          marginBottom: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `mrT${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          marginTop: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `mrL${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          marginLeft: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `mrR${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          marginRight: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `mrLR${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          marginLeft: spacerBreakpointSizes(spacingSize),
          marginRight: spacerBreakpointSizes(spacingSize),
        })}
      `
    case `mrBT${spacingSize['f-vp']}`:
      return css`
        ${breakPoints({
          marginBottom: spacerBreakpointSizes(spacingSize),
          marginTop: spacerBreakpointSizes(spacingSize),
        })}
      `
    default:
      break
  }
  return ''
}

const baseSpacingsGenerator = (spacingClass, spacingSize) => {
  switch (spacingClass) {
    case `padB${spacingSize}`:
      return css`
        padding-bottom: ${spacingSize}px;
      `
    case `padT${spacingSize}`:
      return css`
        padding-top: ${spacingSize}px;
      `
    case `padL${spacingSize}`:
      return css`
        padding-left: ${spacingSize}px;
      `
    case `padR${spacingSize}`:
      return css`
        padding-right: ${spacingSize}px;
      `
    case `padLR${spacingSize}`:
      return css`
        padding-left: ${spacingSize}px;
        padding-right: ${spacingSize}px;
      `
    case `padBT${spacingSize}`:
      return css`
        padding-bottom: ${spacingSize}px;
        padding-top: ${spacingSize}px;
      `
    case `mrB${spacingSize}`:
      return css`
        margin-bottom: ${spacingSize}px;
      `
    case `mrT${spacingSize}`:
      return css`
        margin-top: ${spacingSize}px;
      `
    case `mrL${spacingSize}`:
      return css`
        margin-left: ${spacingSize}px;
      `
    case `mrR${spacingSize}`:
      return css`
        margin-right: ${spacingSize}px;
      `
    case `mrLR${spacingSize}`:
      return css`
        margin-left: ${spacingSize}px;
        margin-right: ${spacingSize}px;
      `
    case `mrBT${spacingSize}`:
      return css`
        margin-bottom: ${spacingSize}px;
        margin-top: ${spacingSize}px;
      `
    default:
      break
  }
  return ''
}

const responsiveSpacingsComposer = Object.keys(spacings.responsive)
  .map(spacingSize => {
    return [
      `padB${spacings.responsive[spacingSize]['f-vp']}`,
      `padT${spacings.responsive[spacingSize]['f-vp']}`,
      `padL${spacings.responsive[spacingSize]['f-vp']}`,
      `padR${spacings.responsive[spacingSize]['f-vp']}`,
      `padLR${spacings.responsive[spacingSize]['f-vp']}`,
      `padBT${spacings.responsive[spacingSize]['f-vp']}`,
      `mrB${spacings.responsive[spacingSize]['f-vp']}`,
      `mrT${spacings.responsive[spacingSize]['f-vp']}`,
      `mrL${spacings.responsive[spacingSize]['f-vp']}`,
      `mrR${spacings.responsive[spacingSize]['f-vp']}`,
      `mrLR${spacings.responsive[spacingSize]['f-vp']}`,
      `mrBT${spacings.responsive[spacingSize]['f-vp']}`,
    ].map(spacingClass => {
      return {
        [spacingClass]: responsiveSpacingsGenerator(
          spacingClass,
          spacings.responsive[spacingSize],
        ),
      }
    })
  })
  .reduce((acc, curr) => acc.concat(curr), [])

const baseSpacingsComposer = Object.keys(spacings.base)
  .map(spacingSize => {
    return [
      `padB${spacings.base[spacingSize]}`,
      `padT${spacings.base[spacingSize]}`,
      `padL${spacings.base[spacingSize]}`,
      `padR${spacings.base[spacingSize]}`,
      `padLR${spacings.base[spacingSize]}`,
      `padBT${spacings.base[spacingSize]}`,
      `mrB${spacings.base[spacingSize]}`,
      `mrT${spacings.base[spacingSize]}`,
      `mrL${spacings.base[spacingSize]}`,
      `mrR${spacings.base[spacingSize]}`,
      `mrLR${spacings.base[spacingSize]}`,
      `mrBT${spacings.base[spacingSize]}`,
    ].map(spacingClass => {
      return {
        [spacingClass]: baseSpacingsGenerator(
          spacingClass,
          spacings.base[spacingSize],
        ),
      }
    })
  })
  .reduce((acc, curr) => acc.concat(curr), [])

const responsiveSpacings = Object.assign(
  {},
  ...responsiveSpacingsComposer,
)
const baseSpacings = Object.assign({}, ...baseSpacingsComposer)

export default { ...baseSpacings, ...responsiveSpacings }
