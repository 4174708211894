// Base spacings: [20, 15, 10, 5, 0]
// Responsive spacings: [120, 80, 60, 40, 30]
const spacings = {
  base: {
    0: 0,
    5: 5,
    15: 15,
    10: 10,
    20: 20,
  },
  responsive: {
    30: {
      'f-vp': 30,
      'e-vp': 30,
      'd-vp': 25,
      'c-vp': 25,
      'max-c-vp': 25,
    },
    40: {
      'f-vp': 40,
      'e-vp': 40,
      'd-vp': 30,
      'c-vp': 20,
      'max-c-vp': 20,
    },
    60: {
      'f-vp': 60,
      'e-vp': 60,
      'd-vp': 40,
      'c-vp': 30,
      'max-c-vp': 30,
    },
    80: {
      'f-vp': 80,
      'e-vp': 80,
      'd-vp': 60,
      'c-vp': 40,
      'max-c-vp': 40,
    },
    120: {
      'f-vp': 120,
      'e-vp': 100,
      'd-vp': 80,
      'c-vp': 60,
      'max-c-vp': 60,
    },
    140: {
      'f-vp': 140,
      'e-vp': 120,
      'd-vp': 100,
      'c-vp': 80,
      'max-c-vp': 80,
    },
  },
}

export default spacings
