import { css } from '@emotion/core'
import colorHex from '../tokens/colors'

const colorClasses = []
Object.keys(colorHex).forEach(colorName => {
  colorClasses.push({
    [colorName]: css`
      color: ${colorHex[colorName]};
    `,
  })
})

export default Object.assign({}, ...colorClasses)
