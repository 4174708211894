import { css } from '@emotion/core'
import colors from './tokens/colors'
import { mixins } from './utilities'

const FormFieldResets = css`
  ${'' /* Resets */}
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-input-placeholder {
    color: ${colors.blackTransparent50};
    opacity: 1;
  }

  :-moz-placeholder {
    ${'' /* Firefox 18- */}
    color: ${colors.blackTransparent50};
    opacity: 1;
  }

  ::-moz-placeholder {
    ${'' /* Firefox 19+ */}
    color: ${colors.blackTransparent50};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${colors.blackTransparent50} !important;
    opacity: 1;
  }

  [placeholder]:focus::-webkit-input-placeholder {
    ${mixins.transition()};
    opacity: 0;
  }

  [placeholder]:focus:-moz-placeholder {
    ${mixins.transition()};
    opacity: 0;
  }

  [placeholder]:focus::-moz-placeholder {
    ${mixins.transition()};
    opacity: 0;
  }

  [placeholder]:focus:-ms-input-placeholder {
    ${mixins.transition()};
    opacity: 0;
  }

  ${'' /* Google Autofill Background color */}
  input:-webkit-autofill {
    background-color: ${colors.white} !important;
    -webkit-box-shadow: 0 0 0px 1000px ${colors.white} inset;
    ${
      '' /* use this to change the text color
    -webkit-text-fill-color: */
    }
  }

  ${'' /* Remove the spinners from number input type */}
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input,
  label,
  select,
  button,
  textarea {
    margin: 0;
  }

  textarea {
    height: 130px;
    resize: none;
  }
`

export default FormFieldResets
