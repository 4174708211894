// This file should contain all global styles
import { css } from '@emotion/core'

import FormFieldResets from './FormFieldResets'
import { respondToMax } from './helpers/mixins'
import misc from './tokens/misc'

export default css`
  ${FormFieldResets};

  img {
    vertical-align: bottom;
    height: auto;
  }

  video {
    vertical-align: bottom;
  }

  svg {
    vertical-align: bottom;
  }
  ul,
  ol {
    padding: 0;
    margin: 0;
    &.styled {
      padding-left: 1.5em;
    }
  }
  sup {
    font-size: 0.8em;
    top: 0;
    vertical-align: super;
  }

  ${respondToMax(misc.viewport['c-vp'])} {
    .container:not(.no-push-container) {
      padding-left: ${misc.gutterSpacings['max-c-vp']};
      padding-right: ${misc.gutterSpacings['max-c-vp']};
    }
    .no-push-container {
      padding-left: ${parseInt(misc.rowColumnSpacing) * 2}px;
      padding-right: ${parseInt(misc.rowColumnSpacing) * 2}px;
    }
    .row {
      margin-left: ${parseInt(misc.rowColumnSpacing) * -1}px;
      margin-right: ${parseInt(misc.rowColumnSpacing) * -1}px;
    }
    [class*='col-'] {
      padding-left: ${misc.rowColumnSpacing};
      padding-right: ${misc.rowColumnSpacing};
    }
    .container.edge-container {
      padding-left: 0;
      padding-right: 0;
      > .row {
        margin-left: 0;
        margin-right: 0;
        padding-left: ${misc.rowColumnSpacing};
        padding-right: ${misc.rowColumnSpacing};
      }
    }
  }
`
